<template>
<div class="organ-container">
  <div class="organ-header">
    <div class="header-tab-item">
     {{showLang('com.date.start')}}：
      <DatePicker type="date" v-model="filter.start" style="width: 115px;margin-right:10px"></DatePicker>
      {{showLang('com.date.end')}}：
      <DatePicker type="date" v-model="filter.end" style="width: 115px;margin-right:10px"></DatePicker>
      <Button v-if="funCodes('alrv')" type="primary" @click="getList" style="margin-right:10px">{{showLang('com.op.query')}}</Button>
      <Button v-if="funCodes('alre')" type="success" @click="exportData">{{showLang('com.op.export')}}</Button>
    </div>
  </div>
  <div class="organ-content">
    <Spin fix v-if="spin.show">{{spin.content}}</Spin>
    <div class="chart-area">
      <chartsLine :option="chartsLineData" id="chartsLineData" :key="timer"></chartsLine>
    </div>
    <div class="data-area" ref="table">
      <u-table :data="tabData" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :height="tabHeight" stripe>
        <u-table-column type="index" :label="showLang('com.export.cn.seq')" width="55"></u-table-column>
        <u-table-column prop="time" :label="showLang('com.date')" sortable fit>
          <template slot-scope="scope">
            {{new Date(scope.row.time).format('yyyy-MM-dd')}}
          </template>
        </u-table-column>
        <u-table-column prop="value" :label="showLang('com.stat.average.light.rate')" sortable fit></u-table-column>
      </u-table>
    </div>
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'AnalyseLightonTrend',
  components: {
  },
  props: {
  },
  data(){
    return {
      spin: {
        show: false,
        content: 'Loading...',
      },
      editModal: false,
      tabHeight:500,
      tabData:[],
      filter: {
        start: '',
        end: '',
      },
      timer: 0,
      chartsLineData: {
        title: "",
        x: [],
        unit: "%",
        series: [
          {
            name: "平均亮灯率",
            data: [],
          },
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {}, //下阴影
        },
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('auth', ['appType']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    stationIds: function(){
      return this.selectedDevices.map(p => p.id);
    },
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    selectedNode(){
      this.getList();
    },
  },
  mounted: function(){
    let now = new Date();
    this.filter.end = now.format('yyyy-MM-dd');
    now.setMonth(now.getMonth() - 1);
    this.filter.start = now.format('yyyy-MM-dd');
    setTimeout(this.setTableHeight, 200);
    this.getList();
    this.chartsLineData.series[0].name=this.showLang('com.stat.average.light.rate')
  },
  methods: {
    exportData(){
      let tab = [];
      this.tabData.map(p => {
        tab.push({time: new Date(p.time).format('yyyy-MM-dd'), value: p.value});
      })
      exportXlsx(tab, {
        time: this.showLang('com.date'),
        value: this.showLang('com.stat.average.light.rate'),
      }, this.showLang('com.stat.light.rate.stat.export'));
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 10;
    },
    getList: function(){
      let start = new Date(this.filter.start).format('yyyy-MM-dd');
      let end = new Date(this.filter.end).format('yyyy-MM-dd');
      let groupId = 0, stationId = 0;
      switch (this.selectedNode.type) {
        case "root":
          groupId = 0;
          stationId = 0;
          break;
        case "group":
          groupId = this.selectedNode.id;
          stationId = 0;
          break;
        default:
          groupId = 0;
          stationId = this.selectedNode.id;
          break;
      }
      this.spin.show = true;
      let args = {
        start: start,
        end: end,
        // ids: this.stationIds,
        groupId: groupId,
        stationId: stationId
      }
      let urlType = 'station/analyse/LightOnTrend';
      if(this.appType == 'light'){
        urlType = 'station/light/LightOnTrend';
      }
      this.$axios.post(`//${this.domains.trans}/${urlType}`, args).then(res => {
        this.spin.show = false;
        if(res.code == 0){
          this.$set(this, 'tabData', res.data);
          let x = [], y = [];
          for(let d of res.data){
            x.push(new Date(d.time).format('yyyy-MM-dd'));
            y.push(d.value);
          }
          this.$set(this.chartsLineData, 'x', x);
          this.$set(this.chartsLineData.series[0], 'data', y);
          this.timer++;
        }
      });
    },
  }
}
</script>
<style scoped>
.organ-container{
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.organ-header{
  height: 40px;
  margin: 3px;
  flex: none;
}
.chart-area{
  height: 300px;
  flex: none;
}
.data-area{
  height: 500px;
  flex: auto;
}
.organ-content{
  /* border: solid 1px red; */
  margin: 3px;
  height: 500px;
  flex: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
.header-tab-item{
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 40px;
  /* justify-content: flex-end; */
  /* padding: 0 20px; */
}
</style>
