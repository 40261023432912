<template>
  <div class="organ-container">
    <div class="organ-header">
      <div class="header-tab-item">
        {{showLang('com.date.start')}}：
        <DatePicker type="date" v-model="filter.start" style="width: 115px;margin-right:10px"></DatePicker>
        {{showLang('com.date.end')}}：
        <DatePicker type="date" v-model="filter.end" style="width: 115px;margin-right:10px"></DatePicker>
        <Button v-if="funCodes('aatv')" type="primary" @click="getList"
          style="margin-right:10px">{{showLang('com.op.query')}}</Button>
        <Button v-if="funCodes('aate')" type="success" @click="exportData">{{showLang('com.op.export')}}</Button>
      </div>
    </div>
    <div class="organ-content">
      <Spin fix v-if="spin.show">{{spin.content}}</Spin>
      <div class="chart-area">
        <chartsBar :option="chartsLineData" id="chartsLineData" :key="timer"></chartsBar>
      </div>
      <div class="data-area" ref="table">
        <vxe-table ref="chkTable" border show-overflow show-header-overflow :data="tabData" :height="tabHeight"
          :row-config="{isHover: true}">
          <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')"></vxe-column>
          <vxe-column field="time" :title="showLang('com.state.police.type')" header-align="center">
            <template #default="{ row }">
              {{row.name}}
            </template>
          </vxe-column>
          <vxe-column field="count" :title="showLang('com.data.alarm.num')" header-align="center"></vxe-column>
        </vxe-table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'AnalyseAlarmTypes',
  components: {},
  props: {
  },
  data() {
    return {
      spin: {
        show: false,
        content: 'Loading...',
      },
      editModal: false,
      tabHeight: 500,
      tabData: [],
      filter: {
        start: '',
        end: '',
      },
      timer: 0,
      chartsLineData: {
        // color: ['#5470C6', '#91CC75', '#FAC858'],
        title: "",
        x: [],
        unit: "次",
        series: [
          {
            name: "报警次数",
            data: [],
            barMaxWidth: 30
          },
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {}, //下阴影
        },
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('auth', ['appType']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['alarmLevels', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    stationIds: function () {
      return this.selectedDevices.map(p => p.id);
    },
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.getList();
    },
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 200);
    let now = new Date();
    this.filter.end = now.format('yyyy-MM-dd');
    now.setMonth(now.getMonth() - 1);
    this.filter.start = now.format('yyyy-MM-dd');
    this.getList();
    this.chartsLineData.unit = this.showLang('com.unit.order');
    this.chartsLineData.series[0].name = this.showLang('com.data.alarm.num');
  },
  methods: {
    exportData() {
      this.$refs.chkTable.exportData({ type: 'csv' })
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 10;
    },
    getList: function () {
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == this.productCodes.station){
        stationId = this.selectedNode.id;
      }else{
        groupId = this.selectedNode.id;
      }
      let start = new Date(this.filter.start).format('yyyy-MM-dd');
      let end = new Date(this.filter.end).format('yyyy-MM-dd');
      this.spin.show = true;
      let args = {
        start: start,
        end: end,
        stationId: stationId,
        groupId: groupId,
      }
      // let urlType = 'analyse/alarm/type';
      let urlType = 'station/alarm/AnalyseLightByType';
      // if (this.appType == 'light') {
      //   urlType = 'station/light/AlarmType';
      // }
      this.$axios.post(`//${this.domains.trans}/${urlType}`, args).then(res => {
        this.spin.show = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          res.data.sort((a, b) => b.value - a.value);
          this.$set(this, 'tabData', res.data);
          let x = [], y = [];
          for (let d of res.data) {
            x.push(d.name);
            y.push(d.count);
          }
          this.$set(this.chartsLineData, 'x', x);
          this.$set(this.chartsLineData.series[0], 'data', y);
          this.timer++;
        }
      });
    },
  }
}
</script>
<style scoped>
.organ-container {
  /* border: solid 1px rgb(180, 109, 3); */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.organ-header {
  height: 40px;
  margin: 3px;
  flex: none;
}
.chart-area {
  height: 300px;
  flex: none;
}
.data-area {
  height: 500px;
  flex: auto;
}
.organ-content {
  /* border: solid 1px red; */
  margin: 3px;
  height: 500px;
  flex: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
.header-tab-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 40px;
  /* justify-content: flex-end; */
  /* padding: 0 20px; */
}
</style>
