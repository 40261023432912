<template>
  <div class="organ-container">
    <div class="organ-header">
      <div class="header-tab-item">
        {{showLang('com.state.type')}}：
        <Select :placeholder="showLang('save.select')" v-model="filter.type" style="width: 115px;margin-right:10px">
          <Option value="year">{{showLang('com.date.annual')}}</Option>
          <Option value="month">{{showLang('com.date.monthly')}}</Option>
          <Option value="date">{{showLang('com.date.day.degree')}}</Option>
        </Select>
        {{showLang('com.date.start')}}：
        <DatePicker :type="filter.type" v-model="filter.start" :format="formats[filter.type]"
          style="width: 115px;margin-right:10px"></DatePicker>
        {{showLang('com.date.end')}}：
        <DatePicker :type="filter.type" v-model="filter.end" :format="formats[filter.type]"
          style="width: 115px;margin-right:10px"></DatePicker>
        <Button v-if="funCodes('aeuv')" type="primary" @click="getList"
          style="margin-right:10px">{{showLang('com.op.query')}}</Button>
        <Button v-if="funCodes('aeue')" type="success" @click="exportData"
          style="margin-right:10px">{{showLang('com.op.export')}}</Button>
        {{showLang('com.data.power.rate')}}：{{price}} {{showLang('com.unit.cash.degree')}}
        <a href="javascript:void(0)"
          :class="[typeCut?'header-typeCut-active':'','header-typeCut ml20 header-typeCut-bor1']"
          @click="typeCut=true">{{showLang('com.data.chart.type')}}</a>
        <a href="javascript:void(0)" :class="[typeCut?'':'header-typeCut-active','header-typeCut header-typeCut-bor2']"
          @click="typeCut=false">{{showLang('com.data.tab.type')}}</a>
        <span class="header-tab-amount">{{showLang('com.stat.total.q')}}：<i>{{amount(1)}}KWh</i></span>
        <span
          class="header-tab-amount">{{showLang('energy.charge.total')}}：<i>{{amount(2)}}{{showLang('com.unit.cash')}}</i></span>
        <span
          class="header-tab-amount">{{showLang('com.stat.save.charge.total')}}：<i>{{amount(3)}}{{showLang('com.unit.cash')}}</i></span>
      </div>
    </div>
    <div class="organ-content">
      <Spin fix v-if="spin.show">{{spin.content}}</Spin>
      <div class="chart-area" v-show="typeCut">
        <chartsLine :option="chartsLineData" id="chartsLineData" :key="timer"></chartsLine>
      </div>
      <div class="data-area" ref="table" v-show="!typeCut">
        <u-table :data="tabData" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :height="tabHeight"
          stripe>
          <u-table-column type="index" :label="showLang('com.export.cn.seq')" width="55"></u-table-column>
          <u-table-column prop="date" :label="showLang('com.date')" sortable fit></u-table-column>
          <u-table-column prop="energy" :label="showLang('com.stat.q.val')" sortable fit></u-table-column>
          <u-table-column prop="rate" :label="showLang('com.stat.saving.rate')" sortable fit></u-table-column>
          <u-table-column prop="pr" :label="showLang('com.stat.rated.energy')" sortable fit></u-table-column>
          <u-table-column prop="money" :label="showLang('energy.charge')" sortable fit></u-table-column>
          <u-table-column prop="save" :label="showLang('com.stat.save.charge')" sortable fit></u-table-column>
        </u-table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'AnalyseEnergyTrend',
  components: {
  },
  props: {
  },
  data() {
    return {
      typeCut: true,
      spin: {
        show: false,
        content: 'Loading...',
      },
      editModal: false,
      tabHeight: 500,
      tabData: [],
      formats: {
        year: 'yyyy',
        month: 'yyyy-MM',
        day: 'yyyy-MM-dd',
      },
      filter: {
        groupId: 0, //分组节点
        stationId: 0, //站点
        type: 'date', // year/month/date
        start: '',
        end: '',
      },
      timer: 0,
      totalData: {
        year: 0,
        month: 0,
        week: 0,
        yestoday: 0,
        today: 0
      },
      chartsLineData: {
        color: ["#02CB9E", "#A468FE", "#FC58B1", "#5A78FC", "#49B0FD"],
        title: "",
        x: [],
        unit: "KWh",
        series: [
          {
            name: "能耗(KWh)",
            data: [],

          },
          {
            name: "节电率(%)",
            data: [],
          },
          {
            name: "额定功率(KW)",
            data: [],
          },
          {
            name: "当日费用(元)",
            data: [],
          },
          {
            name: "节省电费(元)",
            data: [],
          },
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {}, //下阴影
        },
        cover: {
          legend: {
            padding: [5, 10],
            top: '5%',
            data: ['能耗(KWh)', '节电率(%)', '额定功率(kw)', '当日费用(元)', '节省电费(元)']
          },
        }
      },
      price: 1,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('auth', ['appType']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('common', ['productCodes']),
    stationIds: function () {
      return this.selectedDevices.map(p => p.id);
    },
  },
  watch: {
    typeCut() {
      if (this.typeCut) { this.timer++; }
    },
    windowOnResize() {
      this.setTableHeight();
      this.timer++;
    },
    selectedNode() {
      this.getList();
    },
  },
  mounted: function () {
    let now = new Date();
    this.filter.end = now.format('yyyy-MM-dd');
    now.setMonth(now.getMonth() - 1);
    this.filter.start = now.format('yyyy-MM-dd');
    setTimeout(this.setTableHeight, 200);
    this.getList();
    this.getEnergyTotal();
    this.chartsLineData.series[0].name = this.showLang('com.stat.day.energy')
    this.chartsLineData.series[1].name = this.showLang('com.stat.saving.rate')
    this.chartsLineData.series[2].name = this.showLang('energy.before.energyPower')+'(KW)'
    this.chartsLineData.series[3].name = this.showLang('com.stat.day.fee')
    this.chartsLineData.series[4].name = this.showLang('com.stat.save.charge')
    this.chartsLineData.cover.legend.data = [this.showLang('com.stat.q'), this.showLang('com.stat.saving.rate'), this.showLang('com.stat.rated.energy'), this.showLang('com.stat.day.fee'), this.showLang('com.stat.save.charge')]
  },
  methods: {
    amount(index) {
      let num1 = 0, num2 = 0, num3 = 0;
      this.tabData.forEach(el => {
        num1 = num1 + el.energy;
        num2 = num2 + el.money;
        num3 = num3 + el.save;
      });
      if (index == 1) {
        return num1.toFixed(2)
      }
      if (index == 2) {
        return num2.toFixed(2)
      }
      if (index == 3) {
        return num3.toFixed(2)
      }
    },
    getEnergyTotal: function () {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      let urlType = 'analyse';
      if (this.appType == 'light') {
        urlType = 'light';
      }
      this.$axios.post(`//${this.domains.trans}/station/${urlType}/EnergyTotal`, { groupId, stationId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'totalData', res.data);
        }
      });
    },
    exportData() {
      let tab = [];
      this.tabData.map(p => {
        tab.push({
          time: p.date,
          energy: p.energy,
          rate: p.rate,
          pr: p.pr,
          money: p.money,
          save: p.save,
        });
      })
      exportXlsx(tab, {
        time: this.showLang('com.date'),
        energy: this.showLang('com.stat.q'),
        rate: this.showLang('com.stat.saving.rate'),
        pr: this.showLang('com.stat.rated.energy'),
        money: this.showLang('com.stat.day.fee'),
        save: this.showLang('com.stat.save.charge'),
      }, this.showLang('com.stat.energy.derived.record'));
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 10;
    },
    getList: function () {
      let start = new Date(this.filter.start).format('yyyy-MM-dd');
      let end = new Date(this.filter.end).format('yyyy-MM-dd');
      this.spin.show = true;
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      let args = {
        type: this.filter.type,
        start: start,
        end: end,
        groupId,
        stationId,
      }
      let urlType = 'analyse';
      if (this.appType == 'light') {
        urlType = 'light';
      }
      this.$axios.post(`//${this.domains.trans}/station/${urlType}/EnergyCharge`, args).then(res => {
        this.spin.show = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.price = res.data.price;
          this.$set(this, 'tabData', res.data.list);
          let x = [], y1 = [], y2 = [], y3 = [], y4 = [], y5 = [];
          for (let d of res.data.list) {
            x.push(d.date);
            y1.push(d.energy);
            y2.push(d.rate);
            y3.push(d.pr);
            y4.push(d.money);
            y5.push(d.save);
          }
          this.$set(this.chartsLineData, 'x', x);
          this.$set(this.chartsLineData.series[0], 'data', y1);
          this.$set(this.chartsLineData.series[1], 'data', y2);
          this.$set(this.chartsLineData.series[2], 'data', y3);
          this.$set(this.chartsLineData.series[3], 'data', y4);
          this.$set(this.chartsLineData.series[4], 'data', y5);
          this.timer++;
        }
      });
    },
  }
}
</script>
<style scoped>
.organ-container {
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.organ-header {
  margin: 10px 25px;
  height: 40px;
  flex: none;
}
.chart-area {
  height: 99%;
  flex: none;
}
.data-area {
  height: 98%;
  flex: auto;
}
.organ-content {
  /* border: solid 1px red; */
  margin: 3px;
  height: 100%;
  flex: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
}
.header-tab-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 40px;
  /* justify-content: flex-end; */
  /* padding: 0 20px; */
}
.ml20 {
  margin-left: 20px;
}
.header-typeCut {
  border-bottom: 0;
  cursor: pointer;
  padding: 0 10px;
  line-height: 30px;
  background-color: #eef4f9;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
}
.header-typeCut-active {
  background-color: #3880fc;
  color: #fff;
}
.header-typeCut-bor2 {
  border-radius: 0 5px 5px 0;
}
.header-typeCut-bor1 {
  border-radius: 5px 0 0 5px;
}
.header-tab-amount {
  margin-left: 15px;
}
.header-tab-amount i {
  color: #2d8cf0;
  font-style: inherit;
}
</style>
